import {
    CREATE_USER as CREATE,
    CREATE_USER_FAILED as FAILED,
    RESET_FORMS
} from '../../actions/types'

const initialState = {}

const createReducer = (state = initialState, action) => {
    switch(action.type){
        case CREATE:
            return {
                details: action.payload,
                success: 'Created successfully',
            }
        case FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default createReducer