import React from 'react'
import Helmet from 'react-helmet'
import {APP_TITLE} from '../config'
import Banner from './ui/Banner'
import image from '../assets/img/distributor-banner@2x.jpg'
import webP from '../assets/img/distributor-banner_2x.webp'
import {supportsWebP} from '../utils'

class DistributorThankyou extends React.Component {
  render(){
    return(
      <>
        <Helmet>
          <title>Thank You | Become A Distributor | {APP_TITLE}</title>
        </Helmet>
        <Banner heading="Become A Distributor" image={supportsWebP ? webP : image} />
        <section className='_section'>
          <div className='_grid'>
            <div className='_column'>
              <h2 style={{textAlign: 'center', marginTop: '0'}} className="_heading-2"><strong><span className='_orange'>Message sent.</span></strong></h2>
              <p style={{textAlign: 'center'}}>Thank you for submitting your distributor form. A consultant wil be in touch with you shortly.</p>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default DistributorThankyou