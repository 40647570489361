import {Link} from 'react-router-dom'

const WereHereToHelp = () => {
  return (
    <section className="_section _section-were-here-to-help">
      <div className="_grid">
        <div className="_column">
          <h2 className="_heading-1">We’re Here To <strong>Help You</strong></h2>
          <p>Do you have a technical question or require assistance?</p>
          <Link to="/contact-us" className="_button">Contact Us</Link>
        </div>
      </div>
    </section>
  )
}

export default WereHereToHelp