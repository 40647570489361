import React, {useEffect, Suspense} from 'react'
import Helmet from 'react-helmet'
import {APP_TITLE} from '../config'
import Banner from './ui/Banner'
import image from '../assets/img/products-banner@2x.jpg'
import CategoryNavBar from './views/CategoryNavBar'
import {
  fetchPublicProducts,
  sendProductEnquiry,
  resetForms
} from '../actions'
import {connect} from 'react-redux'
import { useParams, Navigate } from 'react-router-dom'
import FuelTypes from './views/FuelTypes'
import Docs from './views/Docs'
import Loader from './ui/Loader'
import ProductEnquiryForm from './forms/ProductEnquiryForm'

const ProductGallerySlider = React.lazy(() => import('./views/ProductGallerySlider'))

const Product = props => {

  const {slug} = useParams()

  useEffect(() => {
    props.fetchPublicProducts(slug)
    return props.resetForms
  }, [])

  const onSubmit = formValues => {
    props.sendProductEnquiry(slug, formValues)
  }

  const data = props.data[slug] ? props.data[slug] : null

  if(!data){
    return <Loader loading={true} />
  }

  if(props.response.success)
    return <Navigate to="/enquiry/thankyou" />

  return(
    <>
      <Helmet>
        <title>Products | {APP_TITLE}</title>
        <meta name="description" content={data.meta_description} />
        <meta name="keywords" content={data.meta_keywords} />
      </Helmet>
      <Banner heading="Products" image={image} />
      <CategoryNavBar />
      <div style={{height: "50px"}} />
      {data && (
        <Suspense fallback="">
          <ProductGallerySlider data={data.images} image={data.preview_image} alt={data.name} />
        </Suspense>
      )}
      <section className='_section'>
        <div className='_grid'>
          <div className='_column'>
            <h1 className='_heading _heading-1'>{data && <strong>{data.name}</strong>}</h1>
            <h2 className='_heading-2'><span className='_orange'>Information</span></h2>
            {data && <div className='_information' dangerouslySetInnerHTML={{__html:data.characteristics}} />}
          </div>
        </div>
      </section>
      <section className='_info-banner'>
        <div className='_grid'>
          <div className='_column'>
            <div className='_item'>
              <p className='_installation-type'>
                <span className='_grey'>Installation Type:</span>
                <span>{data && <strong>&nbsp;{data.instalation_type}</strong>}</span>
              </p>
            </div>
            <div className='_item'>
              {data && <FuelTypes data={data.fuel_types} />}
            </div>
            <div className='_item'>
              {data && <Docs data={data.documents} name={data.name} />}
            </div>
          </div>
        </div>
      </section>
      <section className='_section _product-enquiry-section'>
        <div className='_grid'>
          <div className='_column'>
            <div className='_panel'>
              <h2 className='_heading _heading-1'>Product Enquiry</h2>
              <p>Please fill in the form below and we will get back to you shortly.</p>
              <ProductEnquiryForm onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = state => {
  return {
    data: state.publicProductsState,
    response: state.productEnquiryState
  }
}

export default connect(mapStateToProps, {
  fetchPublicProducts,
  sendProductEnquiry,
  resetForms
})(Product)