import { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import {
  fetchPublicCategories
} from '../../actions'
import {connect} from 'react-redux'
import {scrollToPosition} from '../../utils'

const CategoryNavBar = props => {

  const [open, setOpen] = useState(false)

  useEffect(() => {
    props.fetchPublicCategories()
  }, [])

  const onToggleMenu = () => {
    setOpen(!open)
  }

  const renderItems = () => {

    let records = props.records

    records.sort(function(a, b) {
        if(a.name && b.name){
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
        }
        // names must be equal
        return 0;
    });
    
    return records.map(({id,name,slug}) => {
      return (
        <li key={id}>
          <Link to={`/products#${slug}`} onClick={() => scrollToPosition(`#${slug}`, 100)}>{name}</Link>
        </li>
      )
    })
  }

  return (
    <section className='section-category-navbar'>
      <div className='_grid _grid-fluid'>
        <div className='_column'>
          <div className={`_trigger ${open ? '_open' : ''}`} onClick={onToggleMenu}><span>Categories</span></div>
          <nav className={`_nav ${open ? '_open' : ''}`}>
            <ul>
              {renderItems()}
            </ul>
          </nav>
        </div>
      </div>
    </section>
    
  )
}

const mapStateToProps = state => {
  return{
    records: state.publicCategoriesState
  }
}

export default connect(mapStateToProps, {
  fetchPublicCategories
})(CategoryNavBar)