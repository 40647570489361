import _ from 'lodash'
import {
    FETCH_PRODUCTS as FETCH_ALL,
    CREATE_PRODUCT as CREATE,
    FETCH_PRODUCT as FETCH,
    UPDATE_PRODUCT as UPDATE,
    DELETE_PRODUCT as DELETE,
    RESTORE_PRODUCT as RESTORE
} from '../../actions/types'

const initialState = {}

const indexReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_ALL:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case CREATE:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case FETCH:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case UPDATE:
            return {
                ...state, [action.payload.id]: action.payload
            }   
        case RESTORE:
            return {
                ...state, [action.payload.id]: action.payload
            } 
        case DELETE:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default indexReducer