import { BASE_PATH } from "../../config"

const Docs = props => {

  const renderItems = () => {
    return props.data.map(({id,title,path}) => {

      return (
        <a key={id} download={title ? title : props.name} href={BASE_PATH+path} className="_download-link" target="_blank" rel="noreferrer"><svg fill="#FA5F1A" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path d="M12 21l-8-9h6v-12h4v12h6l-8 9zm9-1v2h-18v-2h-2v4h22v-4h-2z"></path></svg>&nbsp;{title ? title : 'Download Specs'}</a>
      )
    })
  }

  if(!props.data.length > 0){
    return <></>
  }

  return (
    <div className='_docs'>
      <p>Technical Specifations:</p>
      <div>{renderItems()}</div>
    </div>
  )
}

export default Docs