import _ from 'lodash'
import {
    FETCH_FUELTYPES as FETCH_ALL,
    CREATE_FUELTYPE as CREATE,
    FETCH_FUELTYPE as FETCH,
    UPDATE_FUELTYPE as UPDATE,
    DELETE_FUELTYPE as DELETE,
    RESTORE_FUELTYPE as RESTORE
} from '../../actions/types'

const initialState = {}

const indexReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_ALL:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }
        case CREATE:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case FETCH:
            return {
                ...state, [action.payload.id]: action.payload
            }
        case UPDATE:
            return {
                ...state, [action.payload.id]: action.payload
            }   
        case RESTORE:
            return {
                ...state, [action.payload.id]: action.payload
            } 
        case DELETE:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default indexReducer