import React from 'react'
import Helmet from 'react-helmet'
import {APP_TITLE} from '../config'
import WereHereToHelp from './views/WereHereToHelp'
import Banner from './ui/Banner'
import image from '../assets/img/products-banner@2x.jpg'
import {
  fetchPublicProductsCategories
} from '../actions'
import {connect} from 'react-redux'
import CategoryNavBar from './views/CategoryNavBar'
import placeholder from '../assets/img/where-to-buy@2x.jpg'
import {Link} from 'react-router-dom'
import { BASE_PATH } from '../config'
import Loader from './ui/Loader'

class Products extends React.Component {

  componentDidMount(){
    this.props.fetchPublicProductsCategories()
  }

  renderProducts = products => {

    // sort by name
    products.sort(function(a, b) {
        if(a.name && b.name){
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
        }
        // names must be equal
        return 0;
    });
    
    return products.map(({id,name,slug,preview_image,instalation_type}) => {
      return (
        <div className='_column _product-overview-column' key={id}>
          <Link className='_link-item' to={`/products/${slug}`}>
            <div className='_scale'>
              <img src={preview_image ? BASE_PATH+preview_image : placeholder} alt={name} />
            </div>
            <h3 className='_heading-2' style={{marginBottom: "10px"}}>{name}</h3>
            <p style={{marginTop: "10px"}} className="_inst-type">Installation Type:&nbsp;&nbsp;&nbsp;<strong>{instalation_type}</strong></p>
            <p><span className='_orange'><strong>View Product</strong></span></p>
            
          </Link>
        </div>
      )
    })
  }

  renderCategories = () => {
    
    let categories = this.props.categories
    // sort by name
    categories.sort(function(a, b) {
        if(a.name && b.name){
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
        }
        // names must be equal
        return 0;
    });
    return categories.map(({id,name,slug,products}) => {
      return (
        <section id={slug} className='_section' key={id}>
          <div className='_column'>
            <h2 className='_heading _heading-1'>{name}</h2>
          </div>
          <div className='_grid _grid-3'>
            {this.renderProducts(products)}
          </div>
        </section>
      )
    })
  }

  render(){

    if(!this.props.categories.length > 0){
      return <Loader loading={true} />
    }

    return(
      <>
        <Helmet>
          <title>Products | {APP_TITLE}</title>
          <meta name="description" content="Signi Fires manufactures and distributes a wide variety of products including Bioethanol Fireplaces, Braais, Fire Pits, Gas Fireplaces, Pizza Ovens, Suspended Fireplaces and Wood Fireplaces from our facility in Cape Town, South Africa."/>
          <meta name="keywords" content="Signi Fires, Signi Fires Products, Bioethanol Fireplaces, Braais, Fire Pits, Gas Fireplaces, Pizza Ovens, Suspended Fireplaces, Wood Fireplaces, Fireplaces near me, Best fireplaces" />
        </Helmet>
        <Banner heading="Products" image={image} />
        <CategoryNavBar />
        <div className='_products-categories'>
          {this.renderCategories()}
        </div>
        <WereHereToHelp />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    categories: state.publicProductsCategoriesState
  }
}

export default connect(mapStateToProps, {
  fetchPublicProductsCategories
})(Products)