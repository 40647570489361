import React from 'react'
import Helmet from 'react-helmet'
import {APP_TITLE} from '../config'
import WereHereToHelp from './views/WereHereToHelp'
import Banner from './ui/Banner'
import image from '../assets/img/where-to-buy-banner@2x.jpg'
import webP from '../assets/img/where-to-buy-banner_2x.webp'
import {supportsWebP} from '../utils'
import {
  fetchPublicPartners
} from '../actions'
import {connect} from 'react-redux'
import Loader from './ui/Loader'

class WhereToBuy extends React.Component {

    state = {
        selectedProvince: null
    }

    componentDidMount(){
        this.props.fetchPublicPartners()
    }

    renderLocations = () => {
        
        return this.props.partners.map(({name,city,province,address,telephone,email,business_hours,map,website},index) => {
            if(this.state.selectedProvince === '' || this.state.selectedProvince === province || this.state.selectedProvince === null){
                return (
                    <React.Fragment key={index}>
                        {city && (
                            <div className='_grid'>
                                <div className='_column'>
                                    <div className="_group _group-section-subheading"><h2>{city}</h2><div className="_hr" /></div>
                                    {map && window.innerWidth < 550 && (
                                        <>
                                        <div className='_map' dangerouslySetInnerHTML={{__html: map}} />
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        
                        
                        <div className='_grid _grid-2'>
                            <div className='_column'>
                                <div className='_panel'>
                                    {name && (
                                        <h3>{name}</h3>
                                    )}
                                    <div className='_panel-item'>
                                        {address && (
                                            <>
                                                <p><strong>Address</strong></p>
                                                <div dangerouslySetInnerHTML={{__html:address}} />
                                            </>
                                        )}
                                        {telephone && (
                                            <>
                                                <p><strong>Telephone</strong></p>
                                                <div dangerouslySetInnerHTML={{__html:telephone}} />
                                            </>
                                        )}
                                        
                                    </div>
                                    <div className='_panel-item'>
                                        {email && (
                                            <>
                                                <p><strong>Email</strong></p>
                                                <p><a href={`mailto:${email}`}>{email}</a></p>
                                            </>
                                        )}
                                        {website && (
                                            <>
                                                <p><strong>Website</strong></p>
                                                <p><a href={website} target="_blank" rel="noreferrer">{website}</a></p>
                                            </>
                                        )}
                                        {business_hours && (
                                            <>
                                                <p><strong>Business Hours</strong></p>
                                                <div dangerouslySetInnerHTML={{__html:business_hours}} />
                                            </>
                                        )}
                                        
                                    </div>
                                </div>
                            </div>
                            <div className='_column' >
                                {map && window.innerWidth >= 550 && <div className='_map' dangerouslySetInnerHTML={{__html: map}} />}
                            </div>
                        </div><br /><br /><br /><br /><br />
                    </React.Fragment>
                )
            }
            return <></>
        })
    }

    renderOptions = () => {
        
        const options = [
            'Western Province',
            'Eastern Cape',
            'Free State',
            'Gauteng',
            'KwaZulu-Natal',
            'Limpopo',
            'Mpumalanga',
            'Northern Cape',
            'North West',
        ]

        const newArray = []

        for(let i = 0; i < this.props.partners.length; i++){
            if(options.includes(this.props.partners[i].province) ){
                if(!newArray.includes(this.props.partners[i].province)){
                    newArray[i] = this.props.partners[i].province
                }
            }
        }

        return newArray.map((option, index)=>{
            return <option key={index}>{option}</option>
        })
    }

    filterProvince = e => {
        this.setState({
            selectedProvince: e.target.value
        })
    }

    render(){

    if(!this.props.partners.length > 0){
        return <Loader loading={true} />
    }

    return(
      <>
        <Helmet>
          <title>Where To Buy | {APP_TITLE}</title>
          <meta name="robots" content="noindex" />
          <meta name="description" content="Signi Fires has very carefully teamed up with several fireplace distributors who offer an extensive range of our modern fireplace and braai designs. Simply use our location filter to find a Signi Fires distributor near you."/>
          <meta name="keywords" content="Signi Fires, Where to buy Signi Fires products, Where to buy Signi Fires Fireplaces, Where to buy Signi Fires Braais, Signi Fires distributor near me, How do I find a Signi Fires product, Signi Fires where to buy location filter, Signi Fires location filter" />
        </Helmet>
        <Banner heading="Where To Buy" image={supportsWebP ? webP : image} />
        <section className='_section _section-locations'>
            <div className='_grid'>
                <div className='_column'>
                    <p className='_message'>Signi Fires has very carefully teamed up with several fireplace distributors who offer an extensive range of our modern fireplace and braai designs. Whether you are looking to purchase a bioethanol fireplace, a wood fireplace, a gas fireplace or a wood, gas or combo braai, we have you covered. Simply click on the button below and use our location filter to find a Signi Fires distributor near you.</p>
                    <label>Filter Province</label>
                    <select onChange={this.filterProvince} className="_selectProvince">
                        <option value="">All Provinces</option>
                        {this.renderOptions()}
                    </select>
                </div>
            </div>
            {this.renderLocations()}    
        </section>
        <WereHereToHelp />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
      partners: state.publicPartnersState
  }
}

export default connect(mapStateToProps, {
  fetchPublicPartners
})(WhereToBuy)