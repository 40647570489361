import React from 'react'
import Helmet from 'react-helmet'
import {APP_TITLE} from '../config'
import WereHereToHelp from './views/WereHereToHelp'
import Banner from './ui/Banner'
import image from '../assets/img/products@2x.jpg'

class TermsAndConditions extends React.Component {
  render(){
    return(
      <>
        <Helmet>
          <title>Terms & Conditions | {APP_TITLE}</title>
        </Helmet>
        <Banner heading="Terms & Conditions" image={image} />
        <section className='_section _legal-section'>
          <div className='_grid'>
            <div className='_column'>
              <div className='_panel'>

                <p><strong>ORDERING</strong></p>
                <p>1. Quotations are submitted, and orders are only accepted subject to the terms and conditions in the
                28 clauses that follow. These terms supersede any buyer’s terms and conditions except for the
                current Consumer Protection Act. When you make a payment towards the issued quote or invoice
                you agree to these following terms and conditions.</p>

                <p>2. We will take every possible precaution, but it remains the responsibility of the purchaser to
                ensure that written details of products to be purchased are correct on the quote prior to proceeding
                with the order.</p>

                <p>3. For custom products, imported products or products made in 316 stainless steel, a signed drawing
                or approval in writing or receipt of payment, confirms the order. No changes can be made after the
                order is confirmed.</p>

                <p>4. Prices quoted are generally exclusive of delivery, unless otherwise stated on the quote. Delivery is
                included if Signi Fires installs the product unless specialized delivery equipment is required or where
                a traveling charge is levied.</p>

                <p>5. Please note that in the event of imported products that an estimated lead time to the point of
                delivery will be given on confirmation of order and payment received. However, Signi Fires cannot
                be held responsible for any delays encountered during the shipping process. Weather, port
                congestion, canceled freighters, supplier&#39;s delays and other such eventualities are not within the
                company’s control.</p>

                <p>6. Quotes are valid for 48hrs unless otherwise stipulated in writing.</p>

                <p><strong>PAYMENT</strong></p>

                <p>7. All orders require full payment before it is processed. An order cannot be confirmed until full
                payment has been received. Should there be site specific delays Signi Fires will store the product
                free of charge for as long as it reasonably can at its premises or that of its supplier. Where Signi Fires
                cannot store the goods, the customer must collect the goods or take delivery of the goods
                immediately.</p>

                <p>8. It is almost never necessary, but Signi Fires reserves the right to pursue any outstanding funds
                owed to it through listing a defaulter with the national credit bureaus of South Africa.</p>
                <p><strong>DELIVERY / CONTACT</strong></p>
                <p>9. ALL DELIVERIES QUOTED are for delivery only and do not include unpacking, handling, assembly,
                and installation, unless Signi Fires is handling the installation. Any delivery requirements must be
                discussed at the time of placing an order and costs to be confirmed prior to delivery.</p>
                <p>10. Where Signi Fires is not installing or delivering and delivery is done by courier, the delivery is
                deemed &quot;roadside&quot; and does not include moving the goods indoors unless special delivery
                arrangements have been made. Especially, and more specifically, for heavy goods such as braais and
                fireplaces exceeding 70kg&#39;s. The client must have extra hands on site to offload and move the goods
                indoors.</p>
                <p>11. Failed scheduled deliveries (client not available, access refused or no accessible site route) can
                be charged at the current Signi Fires standard rate or the courier rate.</p>
                <p>12. All delivery times quoted are approximate and subject to change prior to delivery. If, for any
                reason delivery is delayed we do not accept liability of any consequential loss but we will arrange a
                new date for delivery as soon as possible thereafter. Signi Fires use the services of courier
                companies to deliver our goods around the country, It is therefore not possible to offer specific
                delivery time during the scheduled delivery day. However, the delivery will be confirmed to be AM
                or PM.</p>
                <p>13. All deliveries must be signed for and checked by the client. Any claim regarding missing packages
                or damaged items must be made within 24 hours of receipt of goods, by telephone, followed by a
                written confirmation. Inspection of the packaging must be done with delivery and should damage to
                the packaging be visible a note must be made on the delivery note/way bill at the time of delivery.
                Photographs must be taken of any damage on delivery, in the presence of the delivery vehicle driver.
                We are unable to claim from our insurance or that of the courier should these steps not be
                completed. Please check all packages especially those products with glass parts carefully.</p>
                <p>14. Risk shall pass to the purchaser on delivery.</p>
                <p><strong>CLEANING, MAINTENANCE and OPERATING INSTRUCTIONS</strong></p>
                <p>15. We recommend that a cleaning maintenance programme is considered to ensure that your
                product maintains a good condition. In terms of exact cleaning details, please refer to the
                manufacturers cleaning instructions. Please inform us should you not have received any care
                instructions or operating manual with your product. Signi Fires cannot be held responsible for the
                products not being fully maintained or correctly operated by the purchaser. If the client is unsure of
                maintenance or operating procedures, please contact Signi Fires to assist.</p>
                <p>16. Gas operated products must be installed by a LPGSA registered installer. Signi Fires cannot be
                held responsible for the incorrect installation of gas fireplaces. The client hereby warrants that they
                are aware that flue less gas fireplaces are not odourless. Should you encounter any problems with a
                gas product you should always contact your gas installer first for an assessment.</p>
                <p>17. No warranty is given against rust or discoloration against any grade of steel.</p>
                <p><strong>RETURNS AND CREDIT</strong></p>
                <p>18. Purchases can be returned within 7 days provided that it’s unused, not opened and in its original
                packaging. This excludes goods that have been custom made to order, made in 316 stainless steel or
                imported at the client&#39;s request. Goods must be undamaged, and it will be at the sole discretion of
                Signi Fires to determine whether the goods are damaged or used.</p>
                <p>19. For imported goods, 316 stainless steel orders and custom products the deposit will not be
                refunded if the customer cancels the order at any stage after confirming the order.</p>
                <p>20. Signi Fires do not offer refunds on purchases returned outside of 7 days. In cases where returns
                are accepted a store credit will be offered minus a 15% handling/restocking/repackaging fee. If a
                confirmed order is cancelled after 7 days but the product was stored for the client, a refund may be
                requested and will incur a 15% administration fee. This excludes any custom orders, 316 stainless
                steel products or imported goods.</p>
                <p>21. Where a customer requests the product to be returned and if the goods were delivered or
                collected from our shop it is the customer’s responsibility to return the product back to Signi Fires.
                Signi Fires can arrange to have the goods collected the cost of which will be for the client’s account
                and must be settled in full prior to collection. Should the customer return the product using a 3rd
                party arranged by Signi Fires or the customer, the customer must arrange for insurance whilst the
                product is being transported. It is the client&#39;s responsibility to ensure the goods are packaged in a
                manner suitable for courier transport. Risk and ownership will only transfer once the goods are
                received in good condition by Signi Fires.</p>
                <p><strong>WARRANTY &amp; INSTALLATION</strong></p>
                <p>22. Brands sold by Signi Fires have varying manufacturer warranty periods, the minimum warranty
                period on any product sold is 24 months from date of purchase. Most brands, however, have longer
                warranty periods. This warranty covers defective parts and bad workmanship. Signi Fires will either
                repair the product or replace the product with the same or a similar product should it find that a
                warranty claim is valid. The decision to replace, repair or reject the claim rests solely with Signi Fires.
                The warranty does not cover wear and tear, incorrect installation, improper use, or a lack of
                maintenance. Refer to the individual product warranty instructions or contact Signi Fires if you are
                unsure.</p>
                <p>23. The customer must return the product to Signi Fires for inspection should there be a warranty
                claim. Should the product be a fixed semi-permanent installation, Signi Fires or a specialist
                representative will come to the premises to establish the claim. Should the claim not be valid, and
                the cause be determined to be 3rd party installation and/or not product related the current
                standard call out fee and hourly rate will be charged. Please first contact your installer to assess your
                product &amp; Installation before contacting Signi Fires. All claims must be accompanied by the warranty
                claim form specific to the product you purchased. The claim form to be completed by your installer.</p>
                <p>24. If Signi Fires installs, the installation is quoted based on what can be seen when measuring or
                when drawings are supplied by the client. Sometimes additional materials such as piping, pipe
                bends, custom brackets and core drilling are required to safely complete the installation. This is
                generally only necessary when complications or unforeseen circumstances arise where we need to
                alter the position of the flue pipe due to proximity to combustible materials causing a threat or potential fire hazard. Additional material will be discussed with the client for approval. If the client
                cannot be reached the installation will continue and the client charged accordingly. Our quotes list
                all items individually, any additional parts required will be added to your final invoice.</p>
                <p>25. Once an installation date is booked it is the client and/or the client’s builder’s responsibility to
                ensure that the site is ready for installation. Should our installer arrive on site and not be able to
                install due to the site not being ready the client will be charged the standard installation rate. We
                urge clients to please be mindful of this when booking installation dates.</p>
                <p>26. Where any wet works such as brick work and plasterwork is required to finish an installation,
                Signi Fires supplies a plastered finish only. We do not offer painting of the plaster as part of our
                service.</p>
                <p>27. Some of our products are hand crafted and changes in appearance and finish is normal and an
                expected feature of these products. This includes the painted finish especially on outdoor products
                when the products will naturally weather.</p>
              </div>
            </div>
          </div>
        </section>
        <WereHereToHelp />
      </>
    )
  }
}

export default TermsAndConditions