import { BASE_PATH } from "../../config"

const FuelTypes = props => {

  const renderItems = () => {
    return props.data.map(({id,title,icon}, index) => {
      return (
        <div className='_item' key={id}>
          <img src={BASE_PATH+icon} alt="" /><br />
          {title}
        </div>
      )
    })
  }

  return (
    <div className='_fuel-types'>
      {renderItems()}
    </div>
  )
}

export default FuelTypes