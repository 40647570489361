import React from 'react'
import {Outlet} from 'react-router-dom'
import '../../assets/scss/app.scss'
import Header from './Header'
import MobileNav from './MobileNav'
import Footer from './Footer'
import Cookie from '../ui/Cookie'

class Layout extends React.Component{

  state = {
    menuOpen: false,
    headerTransform: false,
  }

  componentDidMount(){
    //to close the mobile menu when link is clicked
    const links = document.querySelectorAll("._nav-mobile > ul > li > a")
    for(let i = 0; i < links.length; i++){
      links[i].addEventListener("click", this.onMenuToggle, { capture: true })
    }

    window.addEventListener('scroll', () => {
      this.handleTransform()
    }, { capture: true })
  }

  onMenuToggle = () => {
    this.setState({menuOpen: !this.state.menuOpen})
  }

  handleTransform = () => {

    let y = window.scrollY
    
    if(y > 100){
        if(!this.state.headerTransform){
            this.setState({
                headerTransform: !this.state.headerTransform
            })
        }
    } else {
        if(this.state.headerTransform){
            this.setState({
                headerTransform: !this.state.headerTransform
            })
        }
    }
}

  render(){
    return(
      <>
        <Cookie />
        <Header 
          onMenuToggle={this.onMenuToggle} 
          menuOpen={this.state.menuOpen} 
          headerTransform={this.state.headerTransform} />
        <MobileNav menuOpen={this.state.menuOpen} />
        <Outlet />
        <Footer />
      </>
    )
  }
}

export default Layout