import {useEffect} from 'react'
import {NavLink} from 'react-router-dom'

const NavListItems = props => {

  return (
    <>
      {/* <li>
        <NavLink to="/ui">UI</NavLink>
      </li> */}
      <li>
        <NavLink to="/">Home</NavLink>
      </li>
      <li>
        <NavLink to="/products">Products</NavLink>
      </li>
      <li>
        <NavLink to="/where-to-buy">Where To Buy</NavLink>
      </li>             
      <li>
        <NavLink to="/become-a-distributor">Become A Distributor</NavLink>
      </li>
    </>
  )
}

export default NavListItems