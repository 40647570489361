import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import cookieReducer from './cookieReducer'

import publicCategoriesReducer from './publicCategoriesReducer'
import publicProductsCategoriesReducer from './publicProductsCategoriesReducer'
import publicProductsReducer from './publicProductsReducer'
import publicPartnersReducer from './publicPartnersReducer'

import contactReducer from './contactReducer'
import distributorReducer from './distributorReducer'
import productEnquiryReducer from './productEnquiryReducer'
import authReducer from './authReducer'
import accountReducer from './accountReducer'

import forgotPasswordReducer from './forgotPasswordReducer'
import forgotPasswordOtpReducer from './forgotPasswordOtpReducer'
import forgotPasswordResetReducer from './forgotPasswordResetReducer'

import usersReducer from './users/indexReducer'
import usersBinReducer from './users/binReducer'
import userCreateReducer from './users/createReducer'
import userUpdateReducer from './users/updateReducer'

import categoriesReducer from './categories/indexReducer'
import categoriesBinReducer from './categories/binReducer'
import categoryCreateReducer from './categories/createReducer'
import categoryUpdateReducer from './categories/updateReducer'

import productsReducer from './products/indexReducer'
import productsBinReducer from './products/binReducer'
import productCreateReducer from './products/createReducer'
import productUpdateReducer from './products/updateReducer'

import productImagesReducer from './product-images/indexReducer'
import productImagesBinReducer from './product-images/binReducer'
import productImageCreateReducer from './product-images/createReducer'
import productImageUpdateReducer from './product-images/updateReducer'

import productDocumentsReducer from './product-documents/indexReducer'
import productDocumentsBinReducer from './product-documents/binReducer'
import productDocumentCreateReducer from './product-documents/createReducer'
import productDocumentUpdateReducer from './product-documents/updateReducer'

import partnersReducer from './partners/indexReducer'
import partnersBinReducer from './partners/binReducer'
import partnerCreateReducer from './partners/createReducer'
import partnerUpdateReducer from './partners/updateReducer'

import fueltypesReducer from './fueltypes/indexReducer'
import fueltypesBinReducer from './fueltypes/binReducer'
import fueltypeCreateReducer from './fueltypes/createReducer'
import fueltypeUpdateReducer from './fueltypes/updateReducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["authState", "cookieState"]
}

const rootReducer = combineReducers({
    cookieState: cookieReducer,
    publicCategoriesState: publicCategoriesReducer,
    publicProductsCategoriesState: publicProductsCategoriesReducer,
    publicProductsState: publicProductsReducer,
    publicPartnersState: publicPartnersReducer,
    authState: authReducer,
    accountState: accountReducer,
    contactState: contactReducer,
    distributorState: distributorReducer,
    productEnquiryState: productEnquiryReducer,
    forgotPasswordState: forgotPasswordReducer,
    forgotPasswordOtpState: forgotPasswordOtpReducer,
    forgotPasswordResetState: forgotPasswordResetReducer,
    usersState: usersReducer,
    usersBinState: usersBinReducer,
    userCreateState: userCreateReducer,
    userUpdateState: userUpdateReducer,
    categoriesState: categoriesReducer,
    categoriesBinState: categoriesBinReducer,
    categoryCreateState: categoryCreateReducer,
    categoryUpdateState: categoryUpdateReducer,
    productsState: productsReducer,
    productsBinState: productsBinReducer,
    productCreateState: productCreateReducer,
    productUpdateState: productUpdateReducer,
    productImagesState: productImagesReducer,
    productImagesBinState: productImagesBinReducer,
    productImageCreateState: productImageCreateReducer,
    productImageUpdateState: productImageUpdateReducer,
    productDocumentsState: productDocumentsReducer,
    productDocumentsBinState: productDocumentsBinReducer,
    productDocumentCreateState: productDocumentCreateReducer,
    productDocumentUpdateState: productDocumentUpdateReducer,
    partnersState: partnersReducer,
    partnersBinState: partnersBinReducer,
    partnerCreateState: partnerCreateReducer,
    partnerUpdateState: partnerUpdateReducer,
    fueltypesState: fueltypesReducer,
    fueltypesBinState: fueltypesBinReducer,
    fueltypeCreateState: fueltypeCreateReducer,
    fueltypeUpdateState: fueltypeUpdateReducer,
    form: formReducer
})

export default persistReducer(persistConfig, rootReducer)