import React from 'react'
import ContactForm from './forms/ContactForm'
import {Navigate} from 'react-router-dom'
import Helmet from 'react-helmet'
import {APP_TITLE} from '../config'
import {
  sendContactMessage,
  resetForms
} from '../actions'
import {connect} from 'react-redux'
import Banner from './ui/Banner'
import image from '../assets/img/contact-us-banner@2x.jpg'
import webP from '../assets/img/contact-us-banner_2x.webp'
import {supportsWebP} from '../utils'

class Contact extends React.Component {

  componentWillUnmount(){
    this.props.resetForms()
  }

  onSubmit = formValues => {
    this.props.sendContactMessage(formValues)
  }

  render(){

    if(this.props.response.success)
    return <Navigate to="/contact-us/thankyou" />

    return(
      <>
        <Helmet>
          <title>Contact Us | {APP_TITLE}</title>
          <meta name="description" content="Have a question? Get in touch with our team by filling in and submitting the form."/>
          <meta name="keywords" content="Signi Fires, Signi Fires Cape Town, Contact details for Signi Fires, Signi Fires telephone number, Signi Fires contact number, Signi Fires location, Signi Fires email" />
        </Helmet>
        <Banner heading="Contact Us" image={supportsWebP ? webP : image} />
        <section className='_section'>
          <div className='_grid'>
            <div className='_column'>
              <p style={{textAlign: 'center', marginTop: '0'}}>Have a question? Get in touch with our team using the form below.</p><br /><br />
              <div className='_panel'>
                <ContactForm 
                  onSubmit={this.onSubmit}
                  errors={this.props.response.errors ? this.props.response.errors : null}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    response: state.contactState
  }
}

export default connect(mapStateToProps, {
  sendContactMessage,
  resetForms
})(Contact)