import React from 'react'
import DistributorForm from './forms/DistributorForm'
import {Navigate} from 'react-router-dom'
import Helmet from 'react-helmet'
import {APP_TITLE} from '../config'
import {
  submitDistributorForm,
  resetForms
} from '../actions'
import {connect} from 'react-redux'
import WereHereToHelp from './views/WereHereToHelp'
import Banner from './ui/Banner'
import image from '../assets/img/distributor-banner@2x.jpg'
import webP from '../assets/img/distributor-banner_2x.webp'
import {supportsWebP} from '../utils'

class Distributor extends React.Component {

  componentWillUnmount(){
    this.props.resetForms()
  }

  onSubmit = formValues => {
    this.props.submitDistributorForm(formValues)
  }

  render(){

    if(this.props.response.success)
    return <Navigate to="/become-a-distributor/thankyou" />

    return(
      <>
        <Helmet>
          <title>Become A Distributor | {APP_TITLE}</title>
          <meta name="description" content="Are you interested in becoming one of our fireplace and braai distributors? We are looking for passionate and motivated partners that believe as we do, that quality, safety and design are non-negotiable. To find out more, submit your details."/>
          <meta name="keywords" content="Signi Fires, Signi Fires Cape Town, Signi Fires become a distributor, How do I become a Signi Fires distributor?, Signi Fires partners" />
        </Helmet>
        <Banner heading="Become A Distributor" image={supportsWebP ? webP : image} />
        <section className='_section'>
          <div className='_grid'>
            <div className='_column'>
              <p style={{textAlign: 'center', marginTop: '0'}}>Are you interested in becoming one of our fireplace and braai distributors? We are looking for passionate and motivated partners that believe as we do, that quality, safety and design are non-negotiable. All our bioethanol, wood and gas fireplaces, as well as our wood, gas and combo braais, have been designed and built using the highest quality materials. To find out more, click the button below.</p><br /><br />
              <div className='_panel'>
                <DistributorForm 
                  onSubmit={this.onSubmit}
                  errors={this.props.response.errors ? this.props.response.errors : null}
                />
              </div>
            </div>
          </div>
        </section>
        <WereHereToHelp />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    response: state.distributorState
  }
}

export default connect(mapStateToProps, {
  submitDistributorForm,
  resetForms
})(Distributor)