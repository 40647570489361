import _ from 'lodash'
import {
    FETCH_PRODUCT_DOCUMENTS_BIN as FETCH_ALL,
    RESTORE_PRODUCT_DOCUMENT as RESTORE,
    FORCE_DELETE_PRODUCT_DOCUMENT as DELETE
} from '../../actions/types'

const initialState = {}

const binReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_ALL:
            return {
                ...state, [action.productId]: {..._.mapKeys(action.payload, 'id')}
            } 
        case RESTORE:
            return {
                ...state, [action.productId]: _.omit(state[action.productId], action.payload.id)
            }
        case DELETE:
            return {
                ...state, [action.productId]: _.omit(state[action.productId], action.payload)
            } 
        default:
            return state
    }
}

export default binReducer