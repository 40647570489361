import React from 'react'
import Helmet from 'react-helmet'
import {APP_TITLE} from '../config'
import WereHereToHelp from './views/WereHereToHelp'
import image from '../assets/img/products@2x.jpg'
import imageWebP from '../assets/img/products_2x.webp'
import image1 from '../assets/img/who-we-are@2x.jpg'
import image1WebP from '../assets/img/who-we-are_2x.webp'
import image2 from '../assets/img/where-to-buy@2x.jpg'
import image2WebP from '../assets/img/where-to-buy_2x.webp'
import image3 from '../assets/img/become-a-distributor@2x.jpg'
import image3WebP from '../assets/img/become-a-distributor_2x.webp'
import ZigZagLayout from './ui/ZigZagLayout'
import {Link} from 'react-router-dom'
import {supportsWebP} from '../utils'

class Home extends React.Component {

  render(){

    const data = [
      {
        img: supportsWebP ? imageWebP : image,
        heading: (<h1 className='_heading-1'>Experience Fire<br /><span className='_orange'><strong>Like Never Before</strong></span></h1>),
        content: (<Link to="/products" className="_button" style={{width: '141px'}}>View Products</Link>)
      },
      {
        img: supportsWebP ? image1WebP : image1,
        heading: (<h2 className='_heading-1'>Who We <span className='_orange'><strong>Are</strong></span></h2>),
        content: (<p>Signi Fires specialises in manufacturing world-class braais (BBQs) as well as wood, gas and bioethanol fireplaces from our facility in Cape Town, South Africa. We distribute our products to dealers throughout South Africa and internationally. Our goal is to provide our customers with a single distribution point for fireplaces and braais that are unique to their needs and that challenge the norm.</p>)
      },
      {
        img: supportsWebP ? image2WebP : image2,
        heading: (<h2 className='_heading-1'>Where To <span className='_orange'><strong>Buy</strong></span></h2>),
        content: (<p>Signi Fires has very carefully teamed up with several fireplace distributors who offer an extensive range of our modern fireplace and braai designs. Whether you are looking to purchase a bioethanol fireplace, a wood fireplace, a gas fireplace or a wood, gas or combo braai, we have you covered. Simply click on the button below and use our location filter to find a Signi Fires distributor near you.</p>)
      },
      {
        img: supportsWebP ? image3WebP : image3,
        heading: (<h2 className='_heading-1'>Become A <span className='_orange'><strong>Distributor</strong></span></h2>),
        content: (<p>Are you interested in becoming one of our fireplace and braai distributors? We are looking for passionate and motivated partners that believe as we do, that quality, safety and design are non-negotiable. All our bioethanol, wood and gas fireplaces, as well as our wood, gas and combo braais, have been designed and built using the highest quality materials. To find out more, click the button below.</p>)
      },
    ]

    return(
      <>
        <Helmet>
          <title>Home | {APP_TITLE}</title>
          <meta name="description" content="Signi Fires specialises in manufacturing world-class braais (BBQs) as well as wood, gas and bioethanol fireplaces from our facility in Cape Town, South Africa. We distribute our products to dealers throughout South Africa and internationally."/>
          <meta name="keywords" content="Signi Fires, Signi Fires Cape Town, Fireplaces, Braais, Fire Pits, Pizza Ovens, Fireplace manufacturer near me, Braai manufacturer near me" />
        </Helmet>
        <ZigZagLayout data={data} />
        <WereHereToHelp />
      </>
    )
  }
}

export default Home