export const RESET_FORMS =  'RESET_FORMS'
export const ACCEPT_COOKIE = 'ACCEPT_COOKIE'

//public
export const FETCH_PUBLIC_CATEGORIES =  'FETCH_PUBLIC_CATEGORIES'
export const FETCH_PUBLIC_PRODUCTS_CATEGORIES =  'FETCH_PUBLIC_PRODUCTS_CATEGORIES'
export const FETCH_PUBLIC_PRODUCT =  'FETCH_PUBLIC_PRODUCT'
export const FETCH_PUBLIC_PARTNERS = 'FETCH_PUBLIC_PARTNERS'

// contact
export const SEND_CONTACT_EMAIL =  'SEND_CONTACT_EMAIL'
export const SEND_CONTACT_EMAIL_FAILED =  'SEND_CONTACT_EMAIL_FAILED'
export const SUBMIT_DISTRIBUTOR_FORM = 'SUBMIT_DISTRIBUTOR_FORM'
export const SUBMIT_DISTRIBUTOR_FORM_FAILED = 'SUBMIT_DISTRIBUTOR_FORM_FAILED'
export const SEND_PRODUCT_ENQUIRY =  'SEND_PRODUCT_ENQUIRY'
export const SEND_PRODUCT_ENQUIRY_FAILED =  'SEND_PRODUCT_ENQUIRY_FAILED'

//auth
export const SIGNUP = 'SIGNUP'
export const SIGNUP_FAILED = 'SIGNUP_FAILED'
export const SIGNIN = 'SIGNIN'
export const SIGNIN_FAILED = 'SIGNIN_FAILED'
export const SIGNOUT = 'SIGNOUT'

//password forgot
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED'
export const FORGOT_PASSWORD_OTP = 'FORGOT_PASSWORD_OTP'
export const FORGOT_PASSWORD_OTP_FAILED = 'FORGOT_PASSWORD_OTP_FAILED'
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET'
export const FORGOT_PASSWORD_RESET_FAILED = 'FORGOT_PASSWORD_RESET_FAILED'

//account
export const FETCH_ACCOUNT_DETAILS = 'FETCH_ACCOUNT_DETAILS'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED'

//users
export const FETCH_USERS = 'FETCH_USERS'
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'
export const FETCH_USER = 'FETCH_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'
export const DELETE_USER = 'DELETE_USER'
export const RESTORE_USER = 'RESTORE_USER'
export const FETCH_USERS_BIN = 'FETCH_USERS_BIN'
export const FORCE_DELETE_USER = 'FORCE_DELETE_USERS'

//categories
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED'
export const FETCH_CATEGORY = 'FETCH_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const RESTORE_CATEGORY = 'RESTORE_CATEGORY'
export const FETCH_CATEGORIES_BIN = 'FETCH_CATEGORIES_BIN'
export const FORCE_DELETE_CATEGORY = 'FORCE_DELETE_CATEGORIES'

//products
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED'
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const RESTORE_PRODUCT = 'RESTORE_PRODUCT'
export const FETCH_PRODUCTS_BIN = 'FETCH_PRODUCTS_BIN'
export const FORCE_DELETE_PRODUCT = 'FORCE_DELETE_PRODUCTS'

// product product_images
export const FETCH_PRODUCT_IMAGES = 'FETCH_PRODUCT_IMAGES'
export const CREATE_PRODUCT_IMAGE = 'CREATE_PRODUCT_IMAGE'
export const CREATE_PRODUCT_IMAGES = 'CREATE_PRODUCT_IMAGES'
export const CREATE_PRODUCT_IMAGE_FAILED = 'CREATE_PRODUCT_IMAGE_FAILED'
export const FETCH_PRODUCT_IMAGE = 'FETCH_PRODUCT_IMAGE'
export const UPDATE_PRODUCT_IMAGE = 'UPDATE_PRODUCT_IMAGE'
export const UPDATE_PRODUCT_IMAGE_FAILED = 'UPDATE_PRODUCT_IMAGE_FAILED'
export const DELETE_PRODUCT_IMAGE = 'DELETE_PRODUCT_IMAGE'
export const RESTORE_PRODUCT_IMAGE = 'RESTORE_PRODUCT_IMAGE'
export const FETCH_PRODUCT_IMAGES_BIN = 'FETCH_PRODUCT_IMAGES_BIN'
export const FORCE_DELETE_PRODUCT_IMAGE = 'FORCE_DELETE_PRODUCT_IMAGES'

//product product_documents
export const FETCH_PRODUCT_DOCUMENTS = 'FETCH_PRODUCT_DOCUMENTS'
export const CREATE_PRODUCT_DOCUMENT = 'CREATE_PRODUCT_DOCUMENT'
export const CREATE_PRODUCT_DOCUMENTS = 'CREATE_PRODUCT_DOCUMENTS'
export const CREATE_PRODUCT_DOCUMENT_FAILED = 'CREATE_PRODUCT_DOCUMENT_FAILED'
export const FETCH_PRODUCT_DOCUMENT = 'FETCH_PRODUCT_DOCUMENT'
export const UPDATE_PRODUCT_DOCUMENT = 'UPDATE_PRODUCT_DOCUMENT'
export const UPDATE_PRODUCT_DOCUMENT_FAILED = 'UPDATE_PRODUCT_DOCUMENT_FAILED'
export const DELETE_PRODUCT_DOCUMENT = 'DELETE_PRODUCT_DOCUMENT'
export const RESTORE_PRODUCT_DOCUMENT = 'RESTORE_PRODUCT_DOCUMENT'
export const FETCH_PRODUCT_DOCUMENTS_BIN = 'FETCH_PRODUCT_DOCUMENTS_BIN'
export const FORCE_DELETE_PRODUCT_DOCUMENT = 'FORCE_DELETE_PRODUCT_DOCUMENTS'

//partners
export const FETCH_PARTNERS = 'FETCH_PARTNERS'
export const CREATE_PARTNER = 'CREATE_PARTNER'
export const CREATE_PARTNER_FAILED = 'CREATE_PARTNER_FAILED'
export const FETCH_PARTNER = 'FETCH_PARTNER'
export const UPDATE_PARTNER = 'UPDATE_PARTNER'
export const UPDATE_PARTNER_FAILED = 'UPDATE_PARTNER_FAILED'
export const DELETE_PARTNER = 'DELETE_PARTNER'
export const RESTORE_PARTNER = 'RESTORE_PARTNER'
export const FETCH_PARTNERS_BIN = 'FETCH_PARTNERS_BIN'
export const FORCE_DELETE_PARTNER = 'FORCE_DELETE_PARTNERS'

//fueltypes
export const FETCH_FUELTYPES = 'FETCH_FUELTYPES'
export const CREATE_FUELTYPE = 'CREATE_FUELTYPE'
export const CREATE_FUELTYPE_FAILED = 'CREATE_FUELTYPE_FAILED'
export const FETCH_FUELTYPE = 'FETCH_FUELTYPE'
export const UPDATE_FUELTYPE = 'UPDATE_FUELTYPE'
export const UPDATE_FUELTYPE_FAILED = 'UPDATE_FUELTYPE_FAILED'
export const DELETE_FUELTYPE = 'DELETE_FUELTYPE'
export const RESTORE_FUELTYPE = 'RESTORE_FUELTYPE'
export const FETCH_FUELTYPES_BIN = 'FETCH_FUELTYPES_BIN'
export const FORCE_DELETE_FUELTYPE = 'FORCE_DELETE_FUELTYPES'