import React from 'react'
import NavListItems from './NavListItems'
import Logo from '../ui/Logo'
import {Link} from 'react-router-dom'
import SocialMediaIcons from '../ui/SocialMediaIcons'

class Footer extends React.Component{

  render(){
    return(
      <footer className='_footer'>
        <div className='_grid'>
          <div className='_column _inner-grid'>
            <div className='_item'>
              <Logo />
            </div>
            <div className='_item'>
              <nav className='_footer-nav _bold'>
                <ul>
                  <NavListItems />
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className='_item'>
              <nav className='_footer-nav'>
                <ul>
                  <li>
                    <Link to="privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <a href="mailto:info@signifires.com">info@signifires.com</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className='_item'>
              <SocialMediaIcons />
            </div>
          </div>
        </div>
        <div className='_grid'>
          <div className='_column  _inner-grid'>
            <div className='_flex _copywrite'>
              <p><small>DEVELOPED BY <a href="https://optimalonline.co.za">OPTIMAL ONLINE</a></small></p>
              <div className='_horline' />
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer