import React from 'react'
import Helmet from 'react-helmet'
import {APP_TITLE} from '../config'
import WereHereToHelp from './views/WereHereToHelp'

class Ui extends React.Component {
  render(){
    return(
      <>
        <Helmet>
          <title>Ui | {APP_TITLE}</title>
        </Helmet>
        <section className='_section'>
          <div className='_grid'>
            <div className='_column'>
              <div className='_panel'>
                <h1 className='_heading-1'>Experience Fire<br /><span className='_orange'><strong>Like Never Before</strong></span></h1>
                <h2 className='_heading-2'><strong>Heading 2</strong></h2>
                <p><span className='_grey'><small>Installation Type: <strong>Suspended Fireplace</strong></small></span></p>
                <p>Lorem ipsum dolor sit amet consectetur, <strong>adipisicing elit</strong>. Delectus, libero nisi! Corrupti labore dolores recusandae assumenda exercitationem nihil sed fugiat, tempora, sunt quis quibusdam dicta cupiditate facere itaque repellat dolor.</p>
                <button className='_button'>Contact</button>
              </div>
            </div>
          </div>
        </section>
        <WereHereToHelp />
      </>
    )
  }
}

export default Ui