import React, {Suspense} from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout'
import Home from './Home'
import Contact from './Contact'
import Thankyou from './Thankyou'
import Distributor from './Distributor'
import DistributorThankyou from './DistributorThankyou'
import Signup from './Signup'
import Signin from './Signin'
import ForgotPassword from './ForgotPassword'
import ForgotPasswordOtp from './ForgotPasswordOtp'
import ForgotPasswordReset from './ForgotPasswordReset'
import PageNotFound from './PageNotFound'
import Ui from './Ui'
import Products from './Products'
import Product from './Product'
import WhereToBuy from './WhereToBuy'
import EnquiryThankyou from './EnquiryThankyou'
import TermsAndConditions from './TermsAndConditions'
import PrivacyPolicy from './PrivacyPolicy'
import PageTracker from '../PageTracker'
import ScrollToTop from '../ScrollToTop'
const DashboardLayout = React.lazy(() => import('./dashboard/Layout'))
const DashboardHome = React.lazy(() => import('./dashboard/DashboardHome'))
const DashboardAccount = React.lazy(() => import('./dashboard/DashboardAccount'))
const DashboardUsers = React.lazy(() => import('./dashboard/Users'))
const DashboardUsersBin = React.lazy(() => import('./dashboard/Users/Bin'))
const DashboardUserCreate = React.lazy(() => import('./dashboard/Users/Create'))
const DashboardUserEdit = React.lazy(() => import('./dashboard/Users/Edit'))
const DashboardCategories = React.lazy(() => import('./dashboard/Categories'))
const DashboardCategoriesBin = React.lazy(() => import('./dashboard/Categories/Bin'))
const DashboardCategoryCreate = React.lazy(() => import('./dashboard/Categories/Create'))
const DashboardCategoryEdit = React.lazy(() => import('./dashboard/Categories/Edit'))
const DashboardProducts = React.lazy(() => import('./dashboard/Products'))
const DashboardProductsBin = React.lazy(() => import('./dashboard/Products/Bin'))
const DashboardProductCreate = React.lazy(() => import('./dashboard/Products/Create'))
const DashboardProductEdit = React.lazy(() => import('./dashboard/Products/Edit'))
const DashboardProductImages = React.lazy(() => import('./dashboard/ProductImages'))
const DashboardProductImagesBin = React.lazy(() => import('./dashboard/ProductImages/Bin'))
const DashboardProductImagesCreate = React.lazy(() => import('./dashboard/ProductImages/Create'))
const DashboardProductImagesEdit = React.lazy(() => import('./dashboard/ProductImages/Edit'))
const DashboardProductDocuments = React.lazy(() => import('./dashboard/ProductDocuments'))
const DashboardProductDocumentsBin = React.lazy(() => import('./dashboard/ProductDocuments/Bin'))
const DashboardProductDocumentsCreate = React.lazy(() => import('./dashboard/ProductDocuments/Create'))
const DashboardProductDocumentsEdit = React.lazy(() => import('./dashboard/ProductDocuments/Edit'))
const DashboardPartners = React.lazy(() => import('./dashboard/Partners'))
const DashboardPartnersBin = React.lazy(() => import('./dashboard/Partners/Bin'))
const DashboardPartnerCreate = React.lazy(() => import('./dashboard/Partners/Create'))
const DashboardPartnerEdit = React.lazy(() => import('./dashboard/Partners/Edit'))
const DashboardFueltypes = React.lazy(() => import('./dashboard/Fueltypes'))
const DashboardFueltypesBin = React.lazy(() => import('./dashboard/Fueltypes/Bin'))
const DashboardFueltypeCreate = React.lazy(() => import('./dashboard/Fueltypes/Create'))
const DashboardFueltypeEdit = React.lazy(() => import('./dashboard/Fueltypes/Edit'))
const Dashboard404 = React.lazy(() => import('./dashboard/Dashboard404'))

const App = () => {

  return (
    <BrowserRouter>
      <PageTracker />
      <ScrollToTop />
      <Suspense fallback="">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/ui" element={<Ui />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/contact-us/thankyou" element={<Thankyou />} />
          <Route path="/become-a-distributor" element={<Distributor />} />
          <Route path="/become-a-distributor/thankyou" element={<DistributorThankyou />} />
          {['admin', 'signin', 'login', 'wp-login', 'wp-admin'].map(path => <Route key={path} path={path} element={<Signin />} />)}
          <Route path="/signup" element={<Signup />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password/otp" element={<ForgotPasswordOtp />} />
          <Route path="/forgot-password/reset" element={<ForgotPasswordReset />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:slug" element={<Product />} />
          <Route path="/where-to-buy" element={<WhereToBuy />} />
          <Route path="/enquiry/thankyou" element={<EnquiryThankyou />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        </Route>
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<DashboardHome />} />
          <Route path="/dashboard/account" element={<DashboardAccount />} />

          <Route path="/dashboard/users" element={<DashboardUsers />} />
          <Route path="/dashboard/users/bin" element={<DashboardUsersBin />} />
          <Route path="/dashboard/users/:id" element={<DashboardUserCreate />} />
          <Route path="/dashboard/users/:id/edit" element={<DashboardUserEdit />} />

          <Route path="/dashboard/categories" element={<DashboardCategories />} />
          <Route path="/dashboard/categories/bin" element={<DashboardCategoriesBin />} />
          <Route path="/dashboard/categories/:id" element={<DashboardCategoryCreate />} />
          <Route path="/dashboard/categories/:id/edit" element={<DashboardCategoryEdit />} />

          <Route path="/dashboard/products" element={<DashboardProducts />} />
          <Route path="/dashboard/products/bin" element={<DashboardProductsBin />} />
          <Route path="/dashboard/products/:id" element={<DashboardProductCreate />} />
          <Route path="/dashboard/products/:id/edit" element={<DashboardProductEdit />} />

          <Route path="/dashboard/products/:id/images" element={<DashboardProductImages />} />
          <Route path="/dashboard/products/:id/images/bin" element={<DashboardProductImagesBin />} />
          <Route path="/dashboard/products/:id/images/:imageId" element={<DashboardProductImagesCreate />} />
          <Route path="/dashboard/products/:id/images/:imageId/edit" element={<DashboardProductImagesEdit />} />

          <Route path="/dashboard/products/:id/documents" element={<DashboardProductDocuments />} />
          <Route path="/dashboard/products/:id/documents/bin" element={<DashboardProductDocumentsBin />} />
          <Route path="/dashboard/products/:id/documents/:documentId" element={<DashboardProductDocumentsCreate />} />
          <Route path="/dashboard/products/:id/documents/:documentId/edit" element={<DashboardProductDocumentsEdit />} />

          <Route path="/dashboard/partners" element={<DashboardPartners />} />
          <Route path="/dashboard/partners/bin" element={<DashboardPartnersBin />} />
          <Route path="/dashboard/partners/:id" element={<DashboardPartnerCreate />} />
          <Route path="/dashboard/partners/:id/edit" element={<DashboardPartnerEdit />} />

          <Route path="/dashboard/fueltypes" element={<DashboardFueltypes />} />
          <Route path="/dashboard/fueltypes/bin" element={<DashboardFueltypesBin />} />
          <Route path="/dashboard/fueltypes/:id" element={<DashboardFueltypeCreate />} />
          <Route path="/dashboard/fueltypes/:id/edit" element={<DashboardFueltypeEdit />} />

          <Route path="/dashboard/*" element={<Dashboard404 />} />
        </Route>
      </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App