import React from 'react'
import Helmet from 'react-helmet'
import {APP_TITLE} from '../config'
import WereHereToHelp from './views/WereHereToHelp'
import Banner from './ui/Banner'
import image from '../assets/img/products@2x.jpg'

class PageNotFound extends React.Component {
  render(){
    return(
      <>
        <Helmet>
          <title>404 | {APP_TITLE}</title>
        </Helmet>
        <Banner heading="Page Not Found" image={image} />
        <WereHereToHelp />
      </>
    )
  }
}

export default PageNotFound