import {Link} from 'react-router-dom'
import logo from '../../assets/img/signi-fires-logo-colour.svg'

const Logo = () => {
  return (
    <Link to="/" className='_logo'>
      <img src={logo} alt="Signifires" width="190" height="50" />
    </Link>
  )
}

export default Logo