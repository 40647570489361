import {Link} from 'react-router-dom'
import facebook from '../../assets/img/facebook-icon.svg'
import instagram from '../../assets/img/instagram-icon.svg'

const SocialMediaIcons = () => {
  return (
    <ul className='_social-icons'>
      <li>
        <a href="https://www.facebook.com/signifires/" className='_social-icon' target="_blank" rel="roreferrer">
          <img src={facebook} alt="Facebook" width="40" height="40" />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/signifires/" className='_social-icon' target="_blank" rel="roreferrer">
          <img src={instagram} alt="Instagram" width="40" height="40" />
        </a>
      </li>
    </ul>
  )
}


export default SocialMediaIcons