import {
  FETCH_PUBLIC_CATEGORIES
} from '../actions/types'

const initialState = []

const publicCategoriesReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_PUBLIC_CATEGORIES:
            return action.payload
        default:
            return state
    }
}

export default publicCategoriesReducer