const ZigZagLayout = props => {

  const renderRows = () => {
    return props.data.map((item, index) => {
      return (
        <section style={{backgroundImage: `url(${item.img})`}} key={index}>
          <div className="_grid _grid-2 _grid-fluid">
            <div className="_column">
              {index % 2 != 1 && (
                <><div className="_content">
                  {item.heading}
                  {item.content}
                </div></>
              )}
            </div>
            <div className="_column">
              {(index % 2) == 1 && (
                <><div className="_content">
                  {item.heading}
                  {item.content}
                </div></>
              )}
            </div>
          </div>
        </section>
      )
    })
  }

  return (
    <div className="_zigzag-layout">
      {renderRows()}
    </div>
  )
}

export default ZigZagLayout