import React from 'react'
import Helmet from 'react-helmet'
import {APP_TITLE} from '../config'
import Banner from './ui/Banner'
import image from '../assets/img/contact-us-banner@2x.jpg'

class EnquiryThankyou extends React.Component {

  componentDidMount(){
    window.gtag('event', 'conversion', {'send_to': 'AW-10876077201/M5b6CKT2_pEYEJGJj8Io'})
  }

  render(){
    return(
      <>
        <Helmet>
          <title>Enquiry | {APP_TITLE}</title>
        </Helmet>
        <Banner heading="Enquiry" image={image} />
        <section className='_section'>
          <div className='_grid'>
            <div className='_column'>
              <h2 style={{textAlign: 'center', marginTop: '0'}} className="_heading-2"><strong><span className='_orange'>Message sent.</span></strong></h2>
              <p style={{textAlign: 'center'}}>Thank you for your enquiry. A consultant will be in touch with you shortly.</p>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default EnquiryThankyou