import React from 'react'
import NavListItems from './NavListItems'
import Logo from '../ui/Logo'
import HamburgerIcon from '../ui/HamburgerIcon'
import {Link} from 'react-router-dom'

class Header extends React.Component{

  render(){
    return(
      <header className={`_header ${this.props.headerTransform ? '_transformed' : ''}`}>
        <div className='_grid _grid-fluid'>
          <div className='_column'>
            <Logo />
            <nav className='_nav _nav-widescreen _widescreen'>
              <ul>
                <NavListItems />
                <li>
                  <Link to="/contact-us" className='_button'>Contact Us</Link>
                </li>
              </ul>
            </nav>
            <HamburgerIcon onClick={this.props.onMenuToggle} open={this.props.menuOpen} />
          </div>
        </div>
      </header>
    )
  }
}

export default Header