import React from 'react'
import Helmet from 'react-helmet'
import {APP_TITLE} from '../config'
import WereHereToHelp from './views/WereHereToHelp'
import Banner from './ui/Banner'
import image from '../assets/img/products@2x.jpg'

class PrivacyPolicy extends React.Component {
  render(){
    return(
      <>
        <Helmet>
          <title>Privacy Policy | {APP_TITLE}</title>
        </Helmet>
        <Banner heading="Privacy Policy" image={image} />
        <section className='_section _legal-section'>
          <div className='_grid'>
            <div className='_column'>
              <div className='_panel'>

                <p>We are committed to protecting your privacy and complying with applicable data protection and
                  privacy laws. This privacy policy tells you about our online collection and use of data. The terms of
                  this policy apply to this Website (“Site”) and are subject to the Website Terms and Conditions of Use
                  on <a href="https://www.signifires.com">www.signifires.com</a> unless different terms are specified in a form or contract provided to you by
                  Signi Fires either online or offline. By using this Site, you understand and agree to the terms of this
                  policy. This Site is owned and operated by Signi Fires.</p>

                <p>The Site is intended for use by a South African, but may be accessed both in South Africa and abroad.
                  For data protection purposes, Signi Fires is the controller and, unless otherwise noted, is also the
                  processor of data. Information collected may be retained indefinitely, and may be stored, processed,
                  accessed, and used in jurisdictions whose privacy laws may be different and less protective than
                  those of your home jurisdiction.</p>

                <p>1. Collection of Your Personal Information</p>

                <p>1.1 When you visit this Site, certain kinds of information, such as the Website that referred you to
                  us, your IP address, browser type and language, and access times, may be collected automatically as
                  part of the Site’s operation. We may also collect navigational information, including information
                  about the pages you view, the links you click, and other actions taken in connection with the Site.</p>

                <p>1.2 We may combine your visit and navigational information with personal information that you
                  provide. You may always choose not to provide personal information, but, if you so choose, certain
                  products and services may not be available to you.</p>

                <p>1.3 Personal information (e.g., your name and contact details) is collected when you choose to
                interact with us via the Site such as, for example, contacting us or requesting information.</p>
                
                <p>2. Use of Your Personal Information</p>

                <p>2.1 We collect and use your personal information to operate and improve the site, provide services
                and information to you, to better understand our customers, to provide better products and
                services, to display content that is customized to your location and preferences, to assist us with site
                administration, such as with troubleshooting any problems, detecting fraud, resolving disputes, and
                for any purposes deemed necessary or required by Signi Fires to enforce the Website Terms and
                Conditions of Use www.signifires.com and other terms or conditions applicable to any portion of the
                site.</p>

                <p>2.2 We may also use your contact information to enable ourselves to communicate with you. We
                may send transaction-related communications. We may also send you newsletters or marketing
                communications to inform you of new products or services or other information that may be of
                interest. If you do not wish to receive marketing communications, you should follow the
                “unsubscribe” instructions included within each communication. Please keep in mind that, if you
                choose not to receive marketing communications, you will continue to receive transactional or
                account communications (e.g., confirmation e-mails and account statements), where applicable.</p>
                <p>2.3 We may, for marketing purposes, disclose aggregate statistics (information about the user
                population in general terms) about your personal information to our advertisers, affiliates or
                business partners.</p>
                <p>2.4 Personal information collected by us may be stored and processed in South Africa or any other
                country in which we or our service providers or agents maintain facilities and, by using this site, you
                consent to any such transfer of information outside of your country.</p>
                <p>3. Sharing of Your Personal Information</p>
                <p>3.1 Except as disclosed in this privacy policy, we do not share your personal information with any
                outside parties.</p>
                <p>3.2 We may share your personal information with service providers who perform services on our
                behalf. Those companies or persons will be permitted to obtain only the personal information they
                need to provide the service (for example our courier services). They are required to maintain the
                confidentiality of the information and are prohibited from using it for any other purpose.</p>
                <p>3.3 Information about our users, including personal information, may be disclosed as part of any
                merger, acquisition, or sale of the company and/or its assets, as well as in the unlikely event of
                insolvency, bankruptcy, or receivership, in which case personal information would be transferred as
                one of the business assets of the company. We may notify you of such an occurrence from time to
                time.</p>
                <p>3.4 We reserve the right to disclose your personal information, without notice, if required to do so
                by law, or in the good-faith belief that such action is reasonably necessary to comply with legal
                process, respond to claims, or protect the rights, property or safety of our company, employees,
                users, or the public.</p>
                <p>4. Security of Your Personal Information</p>
                <p>Whilst we cannot guarantee the absolute security of your personal information, we take all
                commercially reasonable steps and measures to keep it secure and protect it from loss, misuse,
                unauthorized access or disclosure by following generally accepted security policies and rules.
                However, we cannot accept any liability whatsoever for any unauthorized access or loss of personal
                information despite our best endeavours.</p>
                <p>5. Personal Information Preferences</p>
                <p>5.1 We respect your right to make choices about the use and disclosure of your personal
                information. If at any time you decide that you do not want to receive marketing communications
                from us, please let us know by sending an e-mail to <a href="mailto:andre@signifires.com">andre@signifires.com</a>.</p>
                <p>5.2 If you choose not to receive marketing communications, please be advised that you may
                continue to receive transactional or account communications (e.g. confirmation e-mails and account
                statements), where applicable.</p>
                <p>6. Access to Your Personal Information</p>
                <p>6.1 You can write to Signi Fires at any time to obtain details of the personal information we may hold
                about you to <a href="mailto:andre@signifires.com">andre@signifires.com</a>.</p>
                <p>6.2 Please quote your name and address and a brief description of the information you want a copy
                of to enable us more readily to locate your data.</p>
                <p>6.3 We will take all reasonable steps to confirm your identity before providing you with details of
                any personal information we may hold about you.</p>
                <p>6.4 We may charge a fee to cover the reasonable administration costs involved.</p>
                <p>7. Use of Cookies and Other</p>
                <p>7.1 Signi Fires may use “cookies” to enable you to sign in to our services and to help personalize your
                online experience. A cookie is a small text file that is placed on your hard drive. Cookies contain
                information, including personal information that can later be read by a Web server in the domain
                that issued the cookie to you. The information that cookies collect may include the date and time of
                your visit, information which you provided when subscribing to a communication or signing up for a
                service, and your navigational history.</p>
                <p>7.2 In some cases, our third-party service providers may use cookies on our Site. We have no access
                to or control over these cookies. This privacy statement covers the use of cookies by Signi Fires only,
                and does not cover the use of cookies by third parties.
                7.3 You have the ability to accept or decline cookies. Most browsers automatically accept cookies,
                but you can usually modify your browser setting to decline cookies. If you choose to decline cookies,
                you may not be able to sign in or use any interactive features of our Site and services that depend on
                cookies.</p>
                <p>7.4 In addition to cookies, we may use other technologies, including single-pixel images on our Site
                and in promotional e-mail messages or newsletters. These tiny electronic images assist us in
                determining how many users have visited certain pages or opened messages or newsletters. We do
                not use these images to collect personal information.</p>
                <p>8. E-Mail Monitoring</p>
                <p>We provide e-mail addresses on the Site which enable you to contact us. In order to check for
                viruses and the presence of inappropriate content, we reserve the right to intercept, monitor and
                inspect all material and information transmitted over our system.</p>
                <p>9. Links</p>
                <p>We may link to Websites that have different privacy policies and practices from those disclosed here.
                We assume no responsibility for the policies or practices of such linked sites, and encourage you to
                become acquainted with them prior to use.</p>
                <p>10. Disclaimers</p>
                <p>10.1 Signi Fires intends to take commercially reasonable precautions to abide by this privacy policy.
                Nevertheless, in the event that we do not comply with all terms contained in this privacy policy, you
                agree, by using this Site and by submitting information to this Site, that your sole and exclusive
                remedy against Signi Fires will be to have us promptly correct the handling of your information in the
                future to accord with this privacy policy and to notify anyone to whom Signi Fires directly transferred
                any information of the correct information or to cease using such information.</p>
                <p>10.2 To the maximum extent allowed by law, Signi Fires, its subsidiaries, affiliates, and divisions
                disclaim any other obligation, liability, or warranty to you for any other action, inaction, omission, or
                other activity that is not in accordance with this privacy policy.</p>
                <p>10.3 Under no circumstances shall Signi Fires, its subsidiaries, affiliates and divisions, or their
                suppliers or licensors be liable for any direct, special, incidental, indirect, economic, consequential or
                punitive damages (including, without limitation, loss of data or loss of use damages or lost profits)
                arising out of or connected with this privacy policy, or the failure of Signi Fires, its subsidiaries,
                affiliates and divisions, or their suppliers or licensors to adhere to it, even if Signi Fires, its
                subsidiaries, affiliates and divisions, or their suppliers or licensors have been notified of the
                possibility of any damages. This section shall only apply to the maximum extent permitted by
                applicable law.</p>
                <p>11. Applicable Law and Jurisdiction</p>
                <p>This privacy policy is governed by and will be interpreted according to the laws of the Republic of
                South Africa, and all disputes, claims and other matters in connection with this privacy policy will be
                determined in accordance with such laws.</p>
                <p>12. Changes to This Privacy Policy</p>
                <p>We reserve the right to change the terms of this privacy policy at any time. When we make changes,
                we will revise the “Last Updated” date at the top of the policy. If there are material changes to this
                statement or in how we will use your personal information, we will notify you by prominently
                posting a notice of such changes here or on our home page, or by sending you an e-mail. We
                encourage you to review this policy whenever you visit our Site.</p>
                <p>13. Contact Information</p>
                <p>If you have questions or concerns regarding this privacy policy or if, for some reason, you believe
                Signi Fires has not adhered to these principles, please contact us by e-mailing <a href="mailto:andre@signifires.com">andre@signifires.com</a>.</p>
              </div>
            </div>
          </div>
        </section>
        <WereHereToHelp />
      </>
    )
  }
}

export default PrivacyPolicy