import React from 'react'

const HamburgerIcon = props => {

  return (
    <button 
      className={`_hamburger-icon _mobile ${props.open ? '_close' : ''}`} 
      onClick={props.onClick}
      aria-label="Menu"
      title="Menu">
      <div>
        <span /><span />
      </div>
    </button>
  )
}

export default HamburgerIcon