const Banner = props => {
  return (
    <section className="_banner" style={{backgroundImage: `url(${props.image})`}}>
      <div className="_grid">
        <div className="_column">
          <h1 className='_heading-1 _heading'>{props.heading}</h1>
        </div>
      </div>
      <div className="_overlay" />
    </section>
  )
}

export default Banner