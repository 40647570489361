import _ from 'lodash'
import {
    FETCH_PRODUCT_DOCUMENTS as FETCH_ALL,
    CREATE_PRODUCT_DOCUMENT as CREATE,
    CREATE_PRODUCT_DOCUMENTS as CREATE_MULTIPLE,
    FETCH_PRODUCT_DOCUMENT as FETCH,
    UPDATE_PRODUCT_DOCUMENT as UPDATE,
    DELETE_PRODUCT_DOCUMENT as DELETE,
    RESTORE_PRODUCT_DOCUMENT as RESTORE
} from '../../actions/types'

const initialState = {}

const indexReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_ALL:
            return {
                ...state, [action.productId]: {..._.mapKeys(action.payload, 'id')}
            }
        case CREATE:
            return {
                ...state, [action.productId]: {
                    [action.payload.id]: action.payload
                }
            }
        case CREATE_MULTIPLE:
            return {
                ...state, [action.productId]: {
                    ...state[action.productId], [action.payload.id]: action.payload
                }
            }
        case FETCH:
            return {
                ...state, [action.productId]: {
                    ...state[action.productId], [action.payload.id]: action.payload
                }
            }
        case UPDATE:
            return {
                ...state, [action.productId]: {
                    ...state[action.productId], [action.payload.id]: action.payload
                }
            }   
        case RESTORE:
            return {
                ...state, [action.productId]: {
                    ...state[action.productId], [action.payload.id]: action.payload
                }
            } 
        case DELETE:
            return {
                ...state, [action.productId]: _.omit(state[action.productId], action.payload)
            } 
            
        default:
            return state
    }
}

export default indexReducer