import React from 'react'
import NavListItems from './NavListItems'
import {Link} from 'react-router-dom'


class MobileNav extends React.Component{

  render(){
    return(
      <nav className={`_nav _nav-mobile _mobile ${this.props.menuOpen ? '_open' : ''}`}>
        <ul>
          <NavListItems />
          <li>
            <Link to="/contact-us" className='_button'>Contact Us</Link>
          </li>
        </ul>
      </nav>
    )
  }
}

export default MobileNav